import React from "react";
import "../../styles/global.css";
import AboutMe from "./AboutMe";
import TechStack from "./TechStack";
import resume from "../../resume.pdf"

const PortfolioTabs = () => {
  const [openTab, setOpenTab] = React.useState(1);
  return (
    <div className=" w-full lg:-translate-y-10 safari-only">
      <ul className="flex flex-nowrap justify-between items-center border-y border-gray-200 px-3">
        <li>
          <a
            className={
              "inline-block py-4 px-4 text-sm font-medium text-center " +
              (openTab === 1
                ? "text-black font-semibold border-b-2 border-cyan-500"
                : "cursor-pointer hover:text-cyan-700 text-gray-500")
            }
            onClick={(e) => {
              e.preventDefault();
              setOpenTab(1);
            }}
            data-toggle="tab"
            href="#link1"
            role="tablist"
          >
            About Me
          </a>
        </li>
        <li>
          <a
            className={
              "inline-block py-4 px-4 text-sm font-medium text-center " +
              (openTab === 2
                ? "text-black font-semibold border-b-2 border-cyan-500"
                : "cursor-pointer hover:text-cyan-700 text-gray-500")
            }
            onClick={(e) => {
              e.preventDefault();
              setOpenTab(2);
            }}
            data-toggle="tab"
            href="#link2"
            role="tablist"
          >
            Tech Stack
          </a>
        </li>

        <li>
          <a
            className={
              "inline-block py-4 px-4 text-sm font-medium text-center " +
              (openTab === 4
                ? "text-black font-semibold border-b-4 border-cyan-500"
                : "cursor-pointer hover:text-cyan-700 text-gray-500")
            }
            data-toggle="tab"
            href="https://www.github.com/ciscoducasse99"
            target={"_blank"}
            rel="noreferrer"
            role="tablist"
          >
            Github
          </a>
        </li>
        <li>
          <a
            className={
              "inline-block py-4 px-4 text-sm font-medium text-center " +
              (openTab === 4
                ? "text-black font-semibold border-b-4 border-cyan-500"
                : "cursor-pointer hover:text-cyan-700 text-gray-500")
            }
            data-toggle="tab"
            href={resume}
            target={"_blank"}
            rel="noreferrer"
            role="tablist"
          >
            Resume
          </a>
        </li>
      </ul>
      <div class=" flex justify-center  align-bottom bg-white rounded-lg text-left overflow-hidden sm:align-middle ">
        <div class="px-4 pt-5 sm:p-6 sm:pb-4 overflow-y-auto max-h-[40rem] portfolio-body">
          <div
            className={openTab === 1 ? "block text-xl px-4 py-2" : "hidden"}
            id="link1"
          >
            <AboutMe />
          </div>
          <div className={openTab === 2 ? "block" : "hidden"} id="link2">
            <TechStack />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PortfolioTabs;
