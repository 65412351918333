import React from "react";
import classNames from "classnames";

const PortalProject = ({
  name,
  images,
  icon,
  link,
  desc,
  task,
  tech,
  tools,
  github,
  handlePortal,
  ppRef,
}) => {
  return (
    // Portal-container
    <div
      className="font-sans bg-white h-full w-full fixed lg:w-4/5 lg:h-5/6 lg:rounded-lg container mx-auto"
      ref={ppRef}
    >
      {/* Header*/}

      {/* Content-container */}
      <div className="h-full overflow-hidden md:mx-auto">
        <div className="flex justify-end pt-5 pr-5  text-cyan-500 hover:text-cyan-700 cursor-pointer">
          <i
            role={"none"}
            onClick={handlePortal}
            className="ml-auto fa fa-close fa-xl"
          />
        </div>
        <div className="grid grid-rows-3 grid-flow-col p-3 gap-x-8 gap-y-2 pb-3 border-b-2 border-slate-200">
          <div className="row-span-3 flex justify-end items-center md:justify-center">
            <img src={icon} alt={name} className="h-28 w-28 rounded" />
          </div>
          <div className="row-span-2 col-span-1">
            <h2 className="text-2xl font-extrabold text-black">{name}</h2>
            <p className="text-slate-500 text-xs md:text-base">{desc}</p>
          </div>
          <div className="row-span-1 col-span-1 mt-auto">
            <div className=" flex justify-between items-center">
              <button className="rounded-full text-xs bg-cyan-500 hover:bg-cyan-700 cursor-pointer text-white px-2 py-1 font-extrabold md:text-sm">
                <a href={link || github} target="_blank" rel="noreferrer">
                  Open Link
                </a>
              </button>
              {github ? (
                <a href={github} target="_blank" rel="noreferrer">
                  <i className="fa fa-brands fa-github fa-xl text-black hover:text-slate-900 cursor-pointer" />
                </a>
              ) : (
                <div />
              )}
            </div>
          </div>
        </div>

        {/* Body */}
        <div className="h-full overflow-auto portfolio-body">
          <div className="p-3">
            <h1 className="text-xl font-extrabold">Tech Stack</h1>

            <div className="flex flex-row overflow-y-auto space-x-2 mb-3">
              {tech.map((tech) => (
                <span
                  key={tech}
                  className="block rounded-md text-sm bg-cyan-500 text-white px-2 py-1 font-extrabold"
                >
                  {tech}
                </span>
              ))}
            </div>
            <h1 className="text-xl font-extrabold">Tools used</h1>
            <div className="flex flex-row overflow-y-auto space-x-2 mb-3">
              {tools.map((tool) => (
                <span
                  key={tool}
                  className="block rounded-md text-sm bg-cyan-500 text-white px-2 py-1 font-extrabold"
                >
                  {tool}
                </span>
              ))}
            </div>
          </div>
          <div className="mb-5 p-3 pt-0">
            <h1 className="text-xl font-extrabold">What's this about?</h1>
            <p className="text-slate-800">{task}</p>
          </div>
          {images !== null ? (
            <div className="mb-64">
              <h1 className="text-xl font-extrabold px-3">Preview</h1>
              <div className="flex flex-row justify-between items-center overflow-x-auto bg-gray-100 rounded-md border-y-2 pb-5 m-5 portfolio-body">
                {images.map((img, imgIdx) => (
                  <img
                    key={imgIdx}
                    src={img}
                    alt={img}
                    className={classNames(
                      " m-5 rounded-md h-[540px] lg:h-2/4",
                      { "w-[1260px] lg:w-1/4": img.charAt(8) === "m" },
                      { "w-[1260px] lg:w-3/4": img.charAt(8) === "d" }
                    )}
                  />
                ))}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default PortalProject;
