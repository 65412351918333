import React, { useState, useRef } from "react";
import SliderWrapper from "../SliderWrapper";
import classNames from "classnames";
import { Link } from "gatsby";
import { scrollIntoView } from "seamless-scroll-polyfill";

const Nav = () => {
  const sliderRef = useRef();

  const [isOpen, setIsOpen] = useState(false);

  const handleSlider = () => {
    if (!isOpen) {
      setIsOpen(true);
      setTimeout(() => {
        scrollIntoView(sliderRef.current, { behavior: "smooth" });
      }, 150);
    } else {
      setIsOpen(false);
    }
  };

  return (
    <>
      <div className="flex flex-row justify-center items-center tracking-widest text-xl lowercase cursor-pointer text-slate-500 mt-20">
        <button
          className={classNames(" mx-5 md:mx-20 hover:text-cyan-500", {
            "text-cyan-500": isOpen,
          })}
          onClick={handleSlider}
        >
          my projects
        </button>
        <Link to={"/blog"}>
          <button className="mx-5 md:mx-20 hover:text-cyan-500">
            my blog
          </button>
        </Link>
      </div>

      <SliderWrapper
        isOpen={isOpen}
        handleSlider={handleSlider}
        sliderRef={sliderRef}
      />
    </>
  );
};

export default Nav;
