import React from "react";

const AboutMe = () => {
  return (
    <>
      <div className="mb-6">
        <h1 className="mb-2 text-xl text-cyan-500 font-semibold">Summary</h1>
        <p className="text-sm font-light">
          As a software engineer, I have experience designing, developing and deploying
          various software applications using different languages, frameworks and tools. I have an eager desire to deliver 
          top-quality products and strive to create impact while working in different scenerios.  My aim is to build revenue for companies and contribute to building out systems using creativity while expanding my skills and knowledge.
        </p>
      </div>
      <div>
        <h1 className="mb-2 text-xl text-cyan-500 font-semibold">Experience</h1>
        <ul className="list-desc">
        <li className=" mb-4 pb-4 border-b-2 border-cyan-500 ">
            <div className="flex space-x-1">
              <div className="text-gray-800 font-medium">Tivly</div>
              <span className="mx-3 text-cyan-500">&#8226;</span>

              <div className="text-gray-500"><small class="text-sm text-gray-600"> May 2023 - Present</small></div>
            </div>
            <div className="mt-1">
              <div>
                <small class=" text-cyan-500 ">
                  Fullstack Software Engineer | Laravel
                </small>
              </div>
              <div className="my-1">
                <ul className="list-disc text-sm space-y-1 px-4 font-light">
                  <li> Maintained and developed different backend components like <span className="font-bold">services, controllers, database tables, APIs and repositories</span></li>
                  <li> Refactor many parts in the codebase to have an event-driven microservice architecure to increase readability and understanding</li>
                  <li> Collaborated with peers to design and build a design system to simplfy and streamline functionality for the Tivly CRM </li>
                  <li> Design and develop different UI components to increase user functionality</li>
                  <li> Created unit tests to boost code quality and minimize code bugs</li>
                </ul>
              </div>
            </div>
          </li>
          <li className=" mb-4 pb-4 border-b-2 border-cyan-500 ">
            <div className="flex space-x-1">
              <div className="text-gray-800 font-medium">Velsera</div>
              <span className="mx-3 text-cyan-500">&#8226;</span>

              <div className="text-gray-500"><small class="text-sm text-gray-600">Jun 2022 - Feb 2023 </small></div>
            </div>
            <div className="mt-1">
              <div>
                <small class=" text-cyan-500 ">
                  Frontend Engineer | Angular Developer
                </small>
              </div>
              <div className="my-1">
                <ul className="list-disc text-sm space-y-1 px-4 font-light">
                  <li>Using <span className="font-bold">Angular</span>, I've built components for the ARIA Cohort-Composer GUI which helps bioinformaticians analyze health data up to the million participant scale</li>
                  <li>Used <span className="font-bold">TypeScript</span> to create interfaces and types to ensure data structure for front-end components</li>
                  <li>Created Angular Services that consume data from Velsera's API to accomplish tasks to help accelerate the creation of cohorts of health data</li>
                  <li>Created unit tests using <span className="font-bold">Jest</span> to ensure code coverage as well as boost code quality and consistency across different regions of development</li>
                  <li>Using strong <span className="font-bold">communication skills</span>, I worked closely with other frontend engineers as well as backend engineers, the design team, product managers and QA to design and develop code</li>
                  <li>Used <span className="font-bold">Git</span> to provide version control as well as create branches of development for different tasks</li>
                  <li>Implemented Velsera's custom styling library to create consistent designs across the whole platform</li>
                  <li>Participated in <span className="font-bold">Agile</span> development processes, including daily standups, sprint planning, and retrospectives to ensure the smooth and transparent delivery of projects.</li>
                  <li>Conducted code reviews to ensure code quality and maintainability for other members on the team.</li>
                </ul>
              </div>
            </div>
          </li>
          <li className=" mb-4 pb-4">
            <div className="flex space-x-1">
              <div className="text-gray-800 font-medium">Freelancer</div>
              <span className="mx-3 text-cyan-500">&#8226;</span>

              <div className="text-gray-500"><small class="text-sm text-gray-600">Dec 2019 - present </small></div>
            </div>
            <div className="mt-1">
              <div>
                <small class="text-cyan-500">
                  Web Developer
                </small>
              </div>
              <div className="my-1">
                <ul className="list-disc text-sm space-y-1 px-4 font-light">
                  <li>Learned the fundamentals of web development including <span className="font-bold">HTML, CSS & Javascript</span></li>
                  <li>Worked with modern CSS frameworks like <span className="font-bold">Sass, Bootstrap & Tailwind</span> to accomplish responsive designs that look fluid on web browsers ranging from mobile-phones to laptops</li>
                  <li>Accelerated front-end development by using popular Javascript frameworks like <span className="font-bold">React.js & Angular</span> to consume data from APIs and respond to user-driven events</li>
                  <li>Designed and implemented <span className="font-bold">RESTful</span> servers in <span className="font-bold">Node.js (Express)</span> to process user requests and communicate with third-party APIs and databases</li>
                  <li>Using database technologies like <span className="font-bold">MySQL and MongoDB</span> as well as cloud-based databases like <span className="font-bold">AWS and Firebase</span>, I've learned how to store users data so servers can make requests and response back with data to the user</li>
                  <li>Using frameworks like <span className="font-bold">Jest & Selenium</span>, I've created unit tests as well as end-to-end testing to ensure code quality</li>
                  <li><span className="font-bold">Collaborated effectively</span> with clients and partners to meet project requirements and provide regular progress updates</li>
                  <li>Developed <span className="font-bold">strong communication</span> and <span className="font-bold">problem-solving</span> skills to troubleshoot issues and meet tight deadlines</li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
};

export default AboutMe;
