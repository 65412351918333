import React, { useEffect, useRef } from "react";
import Portal from "../Portal";
import PortalProject from "../PortalProject";

const SliderProject = (props) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const handlePortal = () => {
    if (isOpen) {
      document
        .getElementById("portal")
        .classList.replace("portal-in", "portal-out");
      setTimeout(() => setIsOpen((prevState) => setIsOpen(!prevState)), 500);
    } else {
      setIsOpen((prevState) => setIsOpen(!prevState));
    }
  };

  const ppRef = useRef();

  useEffect(() => {
    // if slider is open and event.target.click isn't within slider, close slider
    function handleClickOutside(event) {
      if (isOpen && !ppRef.current.contains(event.target)) {
        handlePortal();
      }
    }

    // Bind the event listener
    document.addEventListener("click", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("click", handleClickOutside);
    };
  });

  return (
    <>
      {" "}
      <button
        onClick={handlePortal}
        className=" col-span-1 p-3 border-b-2 rounded-lg border border-gray-200 bg-gray-100 shadow-lg hover:bg-gray-200"
      >
        <img src={props.icon} alt={props.name + "_img"} className="h-16 w-16" />
        <h5 className="mb-1 text-left text-xl font-bold tracking-tight text-cyan-500 ">
          {props.name}
        </h5>
        <div className="text-left">
          <p className="font-normal text-gray-700 text-ellipsis">
            {props.desc}
          </p>
        </div>
      </button>
      {isOpen && (
        <Portal isOpen={isOpen}>
          <PortalProject handlePortal={handlePortal} ppRef={ppRef} {...props} />
        </Portal>
      )}
    </>
  );
};

export default SliderProject;

// USE NGROK FOR DEV
