import React from "react";
// import { CSSTransition } from "react-transition-group";
import "./index.css";
import SliderProject from "../SliderProject";
import { githubRepos } from "../../data/githubrepos";

const SliderWrapper = ({ isOpen, handleSlider, sliderRef }) => {
  const projects = githubRepos;
  return (
    <div
      className={isOpen ? "slidedown" : "slideup"}
      id="Slider-Wrapper"
      ref={sliderRef}
    >
      <div className="flex justify-center">
        <button onClick={handleSlider}>
          <i className="cursor-pointer fa fa-close fa-lg text-cyan-500 p-5" />
        </button>
      </div>
      <div id="Slider" className="text-slate-900 bg-cyan-500 ">
        <div className="flex items-center justify-center mx-5 pt-3">
          <h2 className="text-2xl font-bold text-black">Projects</h2>
        </div>

        {/* Dirty hack to prevent shift after sliderState.content becomes null */}

        <ul className="grid grid-flow-row lg:grid-cols-3 gap-3  p-5">
          {/* <ul className="flex flex-nowrap flex-row lg:flex-col lg:w-full lg:flex-wrap md:space-x-3 p-5"> */}
          {projects.map((project, i) => (
            <SliderProject key={i} {...project} />
          ))}{" "}
        </ul>
      </div>
    </div>
  );
};

export default SliderWrapper;
