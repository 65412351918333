import React, { useEffect, useRef } from "react";
import "./index.css";
import Portal from "../Portal";
import PortalPortfolio from "../PortalPortfolio";

const Orb = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const handlePortal = () => {
    if (isOpen) {
      document
        .getElementById("portal")
        .classList.replace("portal-in", "portal-out");
      setTimeout(() => setIsOpen((prevState) => setIsOpen(!prevState)), 500);
    } else {
      setIsOpen((prevState) => setIsOpen(!prevState));
    }
  };

  const portalRef = useRef();

  useEffect(() => {
    // if slider is open and event.target.click isn't within slider, close slider
    function handleClickOutside(event) {
      if (isOpen && !portalRef.current.contains(event.target)) {
        handlePortal();
      }
    }

    // Bind the event listener
    document.addEventListener("click", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("click", handleClickOutside);
    };
  });

  return (
    <>
    <div className="orb-container mt-32 mb-36 ">
      <div id="orb" onClick={handlePortal} role="presentation" />
      {isOpen && (
        <Portal isOpen={isOpen}>
          <PortalPortfolio handlePortal={handlePortal} portalRef={portalRef} />
        </Portal>
      )}
    </div>
    </>
  );
};

export default Orb;
