import React from "react";
import PortfolioTabs from "./PortfolioTabs";
import Img from "../images/me.jpg";

const PortalPortfolio = ({ handlePortal, portalRef }) => {
  return (
    <div
      className="w-full h-full bg-white lg:w-2/6 lg:h-5/6 lg:rounded-lg overflow-contain"
      ref={portalRef}
    >
      <div className="border-b lg:border-0 lg:-translate-y-10 border-gray-200 p-2 text-cyan-500 ">
        <button onClick={handlePortal} className="cursor-pointer ">
          <i className="fa fa-arrow-left mr-1" />
          Back
        </button>
      </div>
      <div className="flex items-center lg:-translate-y-10">
        <div className="w-20 h-20 m-3 md:w-24 md:h-24 row-span-3">
          <a
            href="https://pbs.twimg.com/profile_images/1282215773247877121/7_DP5WEi_400x400.jpg"
            alt="me"
            target={"_blank"}
            rel="noreferrer"
          >
            <img src={Img} alt="Cisco Ducasse" className="rounded-lg" />
          </a>
        </div>
        <div className="flex flex-col justify-between text-xs md:text-sm">
          <ul className="text-slate-500">
            <div className="flex items-center space-x-2">
              <i className="fa-solid fa-location-crosshairs text-cyan-500" />
              <h1>
                Living Near{" "}
                <span className="font-extrabold text-gray-700">Boston, MA</span>
              </h1>
            </div>
            <div className="flex items-center space-x-2">
              <i className="fa-solid fa-calendar-day text-cyan-500 " />
              <h1>
                Born On{" "}
                <span className="font-extrabold text-gray-700">
                  March 31, 1997
                </span>
              </h1>{" "}
            </div>
          </ul>
          <div className="flex mt-5 space-x-1">
            <div className="text-cyan-500 font-bold ">Francisco Ducasse</div>
            <span className="mx-3">&#8226;</span>

            <div className="text-gray-500">Software Developer (Web)</div>
          </div>
        </div>
      </div>
      <PortfolioTabs />
    </div>
  );
};

export default PortalPortfolio;
