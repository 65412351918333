import cc from "../images/project-icons/cc.png";
import filmtracker from "../images/project-icons/filmtracker.png";
import hnh from "../images/project-icons/hnh.png";
import jbanh from "../images/project-icons/jbanh.png";
import nodejs from "../images/project-icons/nodejs.png";
import portfolio from "../images/project-icons/portfolio.png";
import sizzling from "../images/project-icons/sizzling.png";

import dSizzling1 from "../images/project-imgs/d-sizzling1.jpg";
import dSizzling2 from "../images/project-imgs/d-sizzling2.jpg";
import mSizzling1 from "../images/project-imgs/m-sizzling1.jpg";
import mSizzling2 from "../images/project-imgs/m-sizzling2.jpg";
import mSizzling3 from "../images/project-imgs/m-sizzling3.jpg";
import mSizzling4 from "../images/project-imgs/m-sizzling4.jpg";
import mSizzling5 from "../images/project-imgs/m-sizzling5.jpg";
import mBanh1 from "../images/project-imgs/m-banh1.jpg";
import mBanh2 from "../images/project-imgs/m-banh2.jpg";
import mBanh3 from "../images/project-imgs/m-banh3.jpg";
import mBanh4 from "../images/project-imgs/m-banh4.jpg";
import mBanh5 from "../images/project-imgs/m-banh5.jpg";
import mFilm1 from "../images/project-imgs/m-filmtracker1.jpg";
import mFilm2 from "../images/project-imgs/m-filmtracker2.jpg";
import mFilm3 from "../images/project-imgs/m-filmtracker3.jpg";
import mFilm4 from "../images/project-imgs/m-filmtracker4.jpg";
import mFitness1 from "../images/project-imgs/m-fitness1.jpg";
import mFitness2 from "../images/project-imgs/m-fitness2.jpg";
import mFitness3 from "../images/project-imgs/m-fitness3.jpg";
import mFitness4 from "../images/project-imgs/m-fitness4.jpg";
import mFitness5 from "../images/project-imgs/m-fitness5.jpg";
import mFitness6 from "../images/project-imgs/m-fitness6.jpg";
import mHnh1 from "../images/project-imgs/m-hnh1.jpg";
import mHnh2 from "../images/project-imgs/m-hnh2.jpg";
import mHnh3 from "../images/project-imgs/m-hnh3.jpg";
import mPortfolio1 from "../images/project-imgs/m-portfolio1.jpg";
import mPortfolio2 from "../images/project-imgs/m-portfolio2.jpg";
import mPortfolio3 from "../images/project-imgs/m-portfolio3.jpg";
import mPortfolio4 from "../images/project-imgs/m-portfolio4.jpg";

export const githubRepos = [
  {
    name: "Node-ccbss",
    link: null,
    icon: nodejs,
    images: null,
    github: "https://github.com/ciscoducasse99/node-ccbss",
    tech: ["Node.js"],
    tools: ["Inquirer.js", "Smartsheet", "xml2js", "Express.js"],
    desc: `Command-line interface created to help automate extended search & update process.`,
    task: `While helping at my church, I found a way to help automate a process with code. For months at a time, they'd update the each person's corresponding infomation on a Smartsheet row dedicated to the person. Then when they have a decent list, they'll log in as admin to CCB and update each person manually. The problem was that the Smartsheet file has grown to thousands over the years, so it would be hard to track each and every person. The old process was look at a person's information on Smartsheet, go to CCB and see if there is any differences. If so, update with information on Smartsheet. If not, move to the next row on Smartsheet. This would take weeks of work, even if work was shared amongst a group of people. When I looked into it with intentions of helping, I realized both CCB and Smartsheet had APIs. Using each API, I was able to check and update the list of thousands in a few minutes. `,
  },
  {
    name: "Fitness",
    link: "https://cc-fitness.herokuapp.com",
    icon: cc,
    images: [mFitness1, mFitness2, mFitness3, mFitness4, mFitness5, mFitness6],
    tech: ["Node.js", "MySQL", "React.js"],
    tools: [
      "Knex.js",
      "Passport.js",
      "Bcrypt",
      "Reactstrap",
      "Bootstrap",
      "Sass",
    ],
    desc: `Landing page and dashboard for a fitness instructor`,
    github: "https://github.com/ciscoducasse99/fitness",
    task: `This fitness site started out as helping with a friend. The main site is just a static landing page with a way to buy some affiliated products from Amazon as well as a form that the admin can view. The goal was to make a dashboard that was easy-to-use for someone who would consider themselves as "
    tech-illiterate." `,
  },
  {
    name: "Sizzling",
    link: "https://sizzling.herokuapp.com",
    icon: sizzling,
    images: [
      mSizzling1,
      mSizzling2,
      mSizzling3,
      mSizzling4,
      mSizzling5,
      dSizzling1,
      dSizzling2,
    ],
    github: "https://github.com/ciscoducasse99/sizzling",
    tech: ["React.js", "Node.js", "MySQL"],
    tools: [
      "Sequelize",
      "Redux",
      "Puppeteer.js",
      "Sass",
      "Bootstrap",
      "Normalizr.js",
      "Reactstrap",
    ],
    desc: `Web app made as a simulation to create a host/client food ordering experience`,
    task: `My friend had an idea come to them involving restaurants. The idea is that I (a customer looking to go out) can order food from a resturant I decide to dine in before I even sit down. The app would use the phone's geolocation to check where exactly I am (in this case, I hardcoded that I'm at Applebee's thanks to their menu that I scraped online) and show me a menu once I'm booked in. This app is split into a 'Customer' experience as well as a 'Host' experience. The host has to check in the customer first, then the customer is granted access to the menu. From the host side, they get a bird's eye view on what table is being occupied by what group, what orders go to what table, who is waited to be seated, etc. The Customer side gets to order food which gets sent into the server then displayed for the host. `,
  },
  {
    name: "Filmtracker",
    link: "https://filmtrackerjs.herokuapp.com",
    icon: filmtracker,
    images: [mFilm1, mFilm2, mFilm3, mFilm4],
    github: "https://github.com/ciscoducasse99/filmtracker",
    tech: ["React.js", "MongoDB", "Express.js"],
    tools: [
      "Bcrypt",
      "Mongoose.js",
      "JWT",
      "Passport.js",
      "Sessions",
      "Bootstrap",
    ],
    desc: `Web-app that lets you share view, save and recommended movies to friends and family`,
    task: ` Sticking with the routine of building things I'd want to use, I always wanted to build a movie app that my friend's and I can use. It pretty much started just like any other movie app. Fetch movie data from an app, display it's data, etc. But I thought it would be cool to 'track' them. I love watching films & at any given time, my friends would ask me for a recommendation and I wouldn't be able to think of something off the top of my head. So the idea came where I can have a list of all the films that I loved (or hated) and recommend them to my friends on an app. That way, if I watch something and it reminds me of a special someone, I can send them the movie's info before they even get a chance to ask.`,
  },
  {
    name: "JBanh Consulting",
    link: "https://jbanhconsulting.netlify.app",
    icon: jbanh,
    images: [mBanh1, mBanh2, mBanh3, mBanh4, mBanh5],
    github: "https://github.com/ciscoducasse99/jbanh",
    tech: ["HTML", "CSS", "Javascript"],
    tools: ["Bootstrap", "Swiper", "Purecounter", "Icons", "Glightbox"],
    desc: `Static site for consulting business`,
    task: `This was a follow along using various design tools. The site was made as a placeholder for a friend who was planning on starting a consulting business. To be able to hit the ground running whenever he decided to launch, I created a static website that could display all the information he needed. Bootstrap was the fastest route at the time, but then other tools were included to make the UX feel better. "
   `,
  },
  {
    name: "HNH Bakery",
    link: "https://hnhbakery.herokuapp.com",
    icon: hnh,
    images: [mHnh1, mHnh2, mHnh3],
    github: "https://github.com/ciscoducasse99/hnhbakery",
    tech: ["React.js", "MySQL", "Express.js"],
    tools: ["Chart.js", "Sass", "Bootstrap", "Reactstrap", "Knex.js"],
    desc: "Mobile web app dashboard to help keep track of bakery orders and products",
    task: `This one started out as helping a friend. She was running her business with pen & paper, but it was getting hard for her to keep track of things. I made an app that would help her keep track of client's orders, hold their contact information, run a list of purchases needed, be able to calculate costs, etc. All this would do is make it easier for her to visualize what was happening in her business, without having to go through the hassle of constantly flipping through piles of paper.`,
  },

  {
    name: "Portfolio",
    link: "https://ciscodevelops.vercel.app",
    icon: portfolio,
    images: [mPortfolio1, mPortfolio2, mPortfolio3, mPortfolio4],
    github: null,
    tech: ["React.js"],
    tools: ["Gatsby.js", "TailwindCSS", "Markdown"],
    desc: "Personal Portfolio",
    task: `I always prefer the "minimalistic" design & I love animations. So I figured I'd combine both and sort of "stretch" my creativity. I also really wanted to use TailwindCSS on something, and figured my portfolio could be a good start. Now I can keep track of all my projects and use a personal blog to take notes of things I've learned, want to learn, and things I feel should be noted.`,
  },
];
