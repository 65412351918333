import * as React from "react";
import Layout from "../components/layout";
import Nav from "../components/Nav";
import Orb from "../components/Orb";

// markup
const IndexPage = ({ location }) => {
  const pathname = location.pathname ? location.pathname : "";

  return (
    <Layout pageTitle={"Home"} pathname={pathname} flex={true}>
      <Orb />
      <Nav />
    </Layout>
  );
};

export default IndexPage;
