import React from "react";
import ReactDOM from "react-dom";
import "./index.css";

// First use a ternary operator to make sure that the document object is defined
// If true, give domNode an id of "portal"
const portalRoot =
  typeof document !== `undefined`
    ? document.getElementById("portal-root")
    : null;

export default class Portal extends React.Component {
  constructor() {
    super();

    // Use a ternary operator to make sure that the document object is defined
    // if defined, create element of div with custom className
    this.containerElement =
      typeof document !== `undefined`
        ? Object.assign(
            document.createElement("div"),
            { id: "portal" },
            {
              className:
                "portal-in z-20 absolute overscroll-none overscroll-contain w-full h-screen text-black inset-0 bg-slate-900/[0.7] none flex justify-center items-center",
            }
          )
        : null;
  }

  componentDidMount = () => {
    // attach div to portal node
    portalRoot.appendChild(this.containerElement);
    document.getElementById("main").classList.add("fixed-body");
  };

  componentWillUnmount = () => {
    portalRoot.removeChild(this.containerElement);
    document.getElementById("main").classList.remove("fixed-body");
  };

  render() {
    const { children } = this.props;

    // Check that this.el is not null before using ReactDOM.createPortal
    if (this.props.isOpen) {
      return ReactDOM.createPortal(children, this.containerElement);
    } else {
      return null;
    }
  }
}
